@font-face {
  font-family: Monument Extended;
  font-style: normal;
  src: url("MonumentExtended.803c29bc.otf");
}

@font-face {
  font-family: "Core Sans D 55 Bold";
  font-style: normal;
  src: url("CoreSansD55Bold.4f8e3220.otf");
}

.gradient-list > :not(:last-child) {
  border-right: 1px solid;
  border-image: url("gradient-hr.d638f6c6.svg");
}
/*# sourceMappingURL=index.8e444a18.css.map */
